import React, { useCallback, useEffect } from "react";
import { Button, Divider, HStack, VStack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import InputForm from "components/InputForm";
import InputMaskForm from "components/InputMask";
import { validCpf } from "services/validDocument";
import { onlyNumbers } from "utils/number";
import { DateTime } from "luxon";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { payWithCreditCard } from "services/api.service";
import SelectForm from "components/SelectForm";
import { calcularParcelasMaximas, maskCurrency } from "utils/currency";
import { InstallmentsConfig } from "./types/ChargeTypes";

export interface FormCardCreditCard {
  cardNumber: string;
  cardExpiration: string;
  cardCvv: string;
  cardHolderName: string;
  cardHolderCpf: string;
  cardHolderEmail: string;
  installments: number;
}

const PagSeguroCard: React.FC<{
  id: string;
  amount: number;
  installmentsConfig: InstallmentsConfig;
}> = ({ id, amount, installmentsConfig }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = React.useState(false);
  const { control, handleSubmit } = useForm<FormCardCreditCard>({
    defaultValues: {
      installments: 1,
    },
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    return await executeRecaptcha("PAYMENT_CREDIT_CARD");
    // Do whatever you want with the token
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const onSubmit = async (formData: FormCardCreditCard) => {
    setIsLoading(true);
    const token = await handleReCaptchaVerify();
    payWithCreditCard(id, {
      ...formData,
      cardNumber: onlyNumbers(formData.cardNumber),
      cardHolderCpf: onlyNumbers(formData.cardHolderCpf),
      recaptchaToken: token,
      installments: +formData.installments,
    }).catch(onError);
  };

  const onError = async (error) => {
    setIsLoading(false);
    toast({
      title: error.message ?? "Erro ao realizar pagamento",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  };
  let maxQuantiyParcelas = calcularParcelasMaximas(amount);

  if (maxQuantiyParcelas > 12) {
    maxQuantiyParcelas = 12;
  }

  if (installmentsConfig.maxInstallments < maxQuantiyParcelas) {
    maxQuantiyParcelas = installmentsConfig.maxInstallments;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={3}>
          <InputForm
            type="text"
            name="cardHolderName"
            placeholder="Insira o nome do titular"
            control={control}
            label="Nome do titular"
            rules={{
              required: "Campo obrigatório",
            }}
          />
          <InputMaskForm
            type="text"
            name="cardHolderCpf"
            placeholder="999.999.999-99"
            control={control}
            mask="999.999.999-99"
            label="CPF do titular"
            rules={{
              required: "Campo obrigatório",
              minLength: {
                value: 11,
                message: "CPF inválido",
              },
              validate: {
                valid: (value) => {
                  value = onlyNumbers(value);

                  return validCpf(value) || "Documento inválido";
                },
              },
            }}
          />

          <InputForm
            type="email"
            name="cardHolderEmail"
            placeholder="email@email.com"
            control={control}
            label="E-mail do titular"
            rules={{
              required: "Campo obrigatório",
            }}
          />
          <Divider />

          <InputMaskForm
            type="text"
            name="cardNumber"
            placeholder="XXXX XXXX XXXX XXXX"
            control={control}
            mask="9999 9999 9999 9999"
            label="Número do cartão"
            rules={{
              required: "Campo obrigatório",
              validate: {
                valid: (value) => {
                  value = onlyNumbers(value);
                  return value.length === 16 || "Número do cartão inválido";
                },
              },
            }}
          />
          <HStack width="100%">
            <InputMaskForm
              type="text"
              name="cardExpiration"
              mask="99/9999"
              placeholder="MM/YYYY"
              control={control}
              label="Data de expiração"
              rules={{
                required: "Campo obrigatório",
                minLength: {
                  value: 7,
                  message: "Data inválida",
                },
                maxLength: {
                  value: 7,
                  message: "Data inválida",
                },
                validate: {
                  valid: (value) => {
                    const [month, year] = value.split("/");
                    return (
                      parseInt(month) <= 12 &&
                      parseInt(month) > 0 &&
                      parseInt(year) >= DateTime.now().year
                    );
                  },
                },
              }}
            />

            <InputMaskForm
              type="text"
              name="cardCvv"
              mask="9999"
              placeholder="CVV"
              control={control}
              label="CVV"
              rules={{
                required: "Campo obrigatório",
                minLength: {
                  value: 3,
                  message: "Código inválido",
                },
                maxLength: {
                  value: 4,
                  message: "Código inválido",
                },
              }}
            />
          </HStack>
          <SelectForm
            name="installments"
            label="Parcelas"
            control={control}
            rules={{
              required: "Campo obrigatório",
            }}
            options={[
              { label: `1x - ${maskCurrency(amount)}`, value: "1" },
              ...Array.from({ length: maxQuantiyParcelas - 1 }, (_, i) => {
                const installment = i + 2;

                let newAmount = amount;

                if (installmentsConfig.installmentsType === "FROM_CUSTOMER") {
                  newAmount =
                    newAmount +
                    (amount *
                      installmentsConfig.installments[installment]?.fee) /
                      100;
                }
                const finalMonthly = newAmount / installment;

                return {
                  label: `${installment}x - ${maskCurrency(
                    finalMonthly
                  )} = ${maskCurrency(newAmount)}`,
                  value: installment.toString(),
                };
              }),
            ]}
          />

          <Button
            width="100%"
            mt={3}
            variant="solid"
            isLoading={isLoading}
            colorScheme="brand"
            type="submit"
          >
            Pagar
          </Button>
        </VStack>
      </form>
    </>
  );
};

export default PagSeguroCard;
