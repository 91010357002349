import { AuthProvider } from "contexts/useAuth";
import { useEffect } from "react";
import { getVariable } from "whitelabel";

export default function MasterContext({ children }: any) {
  useEffect(() => {
    document.title = getVariable("name") + " - Acesso";
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link['rel'] = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link['href'] = getVariable("favicon");
  }, []);
  return <AuthProvider>{children}</AuthProvider>;
}
