import {
  useDisclosure,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { estornarPix } from "services/api.service";

const EstornarPagamento: React.FC<{ id: string; refetch: () => void }> = ({
  id,
  refetch,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const cancelRef = React.useRef();
  const toast = useToast();

  const handleEstornar = () => {
    setLoading(true);
    estornarPix(id)
      .then(() => {
        setLoading(false);
        toast({
          title: "Sucesso",
          description: `Pix estornado com sucesso`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        refetch();
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        toast({
          title: "Erro",
          description:
            err.response?.data?.message || "Erro ao cancelar cobrança",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  return (
    <>
      <Tooltip label="Remover">
        <Button
          size="sm"
          colorScheme="red"
          ml={3}
          rounded={"sm"}
          variant={"outline"}
          border={"sm"}
          onClick={() => onOpen()}
        >
          ESTORNAR PAGAMENTO
        </Button>
      </Tooltip>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Estornar Pagamento
            </AlertDialogHeader>

            <AlertDialogBody>
              Tem certeza que deseja estornar o Pagamento?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Fechar
              </Button>
              <Button
                colorScheme="red"
                onClick={handleEstornar}
                isLoading={loading}
                size={"sm"}
                ml={3}
              >
                Estornar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default EstornarPagamento;
