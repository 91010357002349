import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import InputNumber from "components/InputNumber";
import useAuth from "contexts/useAuth";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { createWithdrawalQRCode, validateQRCode } from "services/api.service";
import { maskCurrency } from "utils/number";
import { ShowQrCode } from "../types/QRcode/qrcode.type";
import { queryClient } from "index";

interface CreatePaymentParams {
  amount: number;
  qrcode: string;
  description: string;
}

const CreatePaymentQrcode: React.FC<{}> = () => {
  const toast = useToast();
  const { userData, updateUserData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [qrcode, setQrCode] = React.useState<string | undefined>();
  const [qrCodeData, setQrCodeData] = React.useState<ShowQrCode>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<CreatePaymentParams>();

  const canChangeAmount = () => {
    if (qrCodeData?.type === "DYNAMIC") {
      if (qrCodeData?.payload?.valor?.final) {
        return false;
      }

      if (qrCodeData?.payload?.valor?.modalidadeAlteracao === 1) {
        return true;
      }
    }

    if (qrCodeData?.type === "STATIC" && qrCodeData.transactionAmount === 0) {
      return true;
    }

    return false;
  };

  const createwithdrawel = async (data: CreatePaymentParams) => {
    setLoading(true);
    try {
      await createWithdrawalQRCode({
        ...data,
      });
      queryClient.refetchQueries({
        queryKey: ["findAllWithdrawers"],
        type: "active",
      });
      updateUserData();
      toast({
        title: "Sucesso",
        description: "Saque criado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      toast({
        title: "Erro",
        description: error?.response?.data?.message || "Erro ao criar saque",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!qrcode) return;
    validateQRCode(qrcode).then((response) => {
      if (response) {
        setQrCodeData(response);

        if (response.type === "DYNAMIC") {
          setValue(
            "amount",
            +(
              response?.payload?.valor?.final ??
              response?.payload?.valor?.original ??
              0
            )
          );
          setValue("description", response.payload.solicitacaoPagador);
        } else if (response.type === "STATIC") {
          setValue("amount", response.transactionAmount);
        }
      } else {
        toast({
          title: "Erro",
          description: "QRCode inválido",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    });
  }, [qrcode, setValue, toast]);
  return (
    <>
      <Button
        aria-label="Editar plano"
        rounded="md"
        size="sm"
        variant="outline"
        onClick={() => {
          onOpen();
        }}
      >
        Pix Copia e Cola
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar pagamento por QRCode</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={5} alignItems="start">
                <Text fontWeight="semibold">QRCode</Text>

                <InputForm
                  control={control}
                  label="Pix Copia e Cola"
                  name="qrcode"
                  type="text"
                  onChange={(value) => {
                    setValue("qrcode", value);
                    setQrCode(value);
                  }}
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />

                <InputNumber
                  control={control}
                  percentage={false}
                  placeholder="R$ 0,00"
                  label={"Valor"}
                  disabled={!canChangeAmount()}
                  name={`amount`}
                  rules={{
                    required: "Campo obrigatório",
                    validate: {
                      positive: (value) =>
                        value > 0 || "O valor deve ser maior que 0",
                      max: (value) => {
                        if (
                          value >
                          +userData.balance - +userData.amountLocked
                        ) {
                          return "O valor deve ser menor que o saldo disponível";
                        }

                        /* if (
                          value >
                          (qrCodeData?.payload?.valor?.final ??
                            qrCodeData?.payload?.valor?.original ??
                            qrCodeData.transactionAmount)
                        ) {
                          return "O valor é maior que o valor do QRCode";
                        }
                        */

                        return true;
                      },
                    },
                  }}
                />
                <Text>
                  Saldo Disponível:
                  <b>
                    {maskCurrency(userData.balance - +userData.amountLocked)}
                  </b>
                </Text>

                <Text>Dados do QRCode</Text>
                <Divider />
                {qrCodeData?.payload && (
                  <VStack w="100%">
                    <Text>
                      Original:{" "}
                      <b>
                        {maskCurrency(qrCodeData?.payload?.valor?.original)}
                      </b>
                    </Text>
                    <Text>
                      Juros:{" "}
                      <b>{maskCurrency(qrCodeData?.payload?.valor?.juros)}</b>
                    </Text>
                    <Text>
                      Multa:{" "}
                      <b>{maskCurrency(qrCodeData?.payload?.valor?.multa)}</b>
                    </Text>{" "}
                    <Text>
                      Valor a pagar:{" "}
                      <b>
                        {maskCurrency(
                          qrCodeData?.payload?.valor?.final ??
                            qrCodeData?.payload?.valor?.original ??
                            qrCodeData.transactionAmount
                        )}
                      </b>
                    </Text>
                  </VStack>
                )}
                <FormControl isInvalid={!!errors.description?.message}>
                  <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                    Descrição<Text>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    {...register("description", {
                      maxLength: {
                        value: 100,
                        message: "O limite de caracteres é de 100",
                      },
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    type="text"
                    placeholder="Pagamento de contas"
                  />
                  <FormErrorMessage>
                    {errors.description?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(createwithdrawel)}
                >
                  Criar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreatePaymentQrcode;
