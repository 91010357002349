import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  IconButton,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Card from "components/card/Card";
import useAuth from "contexts/useAuth";
import { useState } from "react";
import { FaCopy } from "react-icons/fa";
import { createCredentials, getCredentials } from "services/api.service";
import ChangePasswordCustomer from "./components/ChangePasswordCustomer";
import Enable2FA from "./Enable2FA";
import { getVariable } from "whitelabel";

export default function Settings() {
  const { userData } = useAuth();
  const toast = useToast();
  const [hidden, setHidden] = useState(true);
  const { data, refetch } = useQuery(["api-key", userData.id], () =>
    getCredentials()
  );

  const generateCredentials = () => {
    createCredentials().then(() => {
      refetch();
      toast({
        title: "Credenciais geradas com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const copy = (value: string) => {
    navigator.clipboard.writeText(value).then(() => {
      toast({
        title: "Copiado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <Card
        flexDirection="column"
        w="100%"
        px={5}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Heading mb={2} fontSize={20}>
          Olá, {userData?.name}
        </Heading>
        <Heading fontSize={20} fontWeight="semibold">
          Configurações
        </Heading>
        <ButtonGroup mt={5}>
          <ChangePasswordCustomer customerId={userData?.id} />
          <Enable2FA />
        </ButtonGroup>
        <Card flexDirection="column" w="100%" p={0} pt={5} pb={3} px="0px">
          <Heading fontSize={14}>Credenciais da API</Heading>
          <Box>
            <Text>
              Key:{" "}
              {hidden ? (
                "---"
              ) : (
                <>
                  {data?.clientId}{" "}
                  <IconButton
                    size="xs"
                    onClick={() => copy(data?.clientId)}
                    rounded="xs"
                    variant="outline"
                    colorScheme="brand"
                    aria-label="Teste"
                    icon={<FaCopy />}
                  />
                </>
              )}
            </Text>
            <Text>
              Secret:{" "}
              {hidden ? (
                "---"
              ) : (
                <>
                  {data?.clientSecret}{" "}
                  <IconButton
                    size="xs"
                    onClick={() => copy(data?.clientSecret)}
                    rounded="xs"
                    variant="outline"
                    colorScheme="brand"
                    aria-label="Teste"
                    icon={<FaCopy />}
                  />
                </>
              )}
            </Text>
            <ButtonGroup>
              <Button
                onClick={() => setHidden(!hidden)}
                size="xs"
                rounded="xs"
                variant="outline"
                colorScheme="brand"
              >
                <Text ml={2}>{hidden ? "Exibir" : "Ocultar"}</Text>
              </Button>

              <Button
                onClick={generateCredentials}
                size="xs"
                isDisabled={!!data}
                rounded="xs"
                variant="outline"
                colorScheme="brand"
              >
                <Text ml={2}>Gerar Credentials</Text>
              </Button>
            </ButtonGroup>
          </Box>

          <Heading mt={5} fontSize={14}>
            Documentação
          </Heading>

          <Text>
            <Link href={getVariable("docs")} target="_blank">
              Documentação {getVariable("name")}
            </Link>
          </Text>
        </Card>
      </Card>
    </Box>
  );
}
