import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import InputNumber from "components/InputNumber";
import useAuth from "contexts/useAuth";
import React from "react";
import { useForm } from "react-hook-form";
import { createTransfer } from "services/api.service";
import { validCnpj, validCpf } from "services/validDocument";
import { maskCurrency, onlyNumbers } from "utils/number";

const TransferInternal: React.FC<{}> = () => {
  const toast = useToast();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { userData, updateUserData } = useAuth();
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{
    document: string;
    description: string;
    amount: number;
  }>();

  const TransferInternal = async (data: any) => {
    setLoading(true);
    try {
      await createTransfer({
        ...data,
        document: onlyNumbers(data.document),
      });
      updateUserData();
      toast({
        title: "Sucesso",
        description: "Transfêrencia criada com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      toast({
        title: "Erro",
        description:
          error?.response?.data?.message || "Erro ao criar transfêrencia",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        colorScheme="brand"
        px={5}
        size="sm"
        mb={2}
        width={{ base: "100%", md: "auto" }}
        rounded="sm"
        variant="outline"
        onClick={onOpen}
      >
        Transf. Interna
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Saque</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={5} alignItems="start">
                <Text fontWeight="semibold">Dados recebedor</Text>
                <FormControl mt={5} isInvalid={!!errors?.document}>
                  <FormLabel display="flex" ms="4px" fontSize="sm">
                    CNPJ<Text>*</Text>
                  </FormLabel>
                  <Input
                    size="sm"
                    isRequired={true}
                    isInvalid={!!errors.document}
                    fontSize="sm"
                    name="document"
                    {...register("document", {
                      required: true,
                      validate: (value) => {
                        value = onlyNumbers(value);
                        if (validCnpj(value)) return true;
                        if (validCpf(value)) return true;
                        return "Não é válido";
                      },
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    type="tel"
                    placeholder="CPF/CNPJ"
                    required
                  />
                  <FormErrorMessage>
                    {errors.document?.message}
                  </FormErrorMessage>
                </FormControl>

                <InputNumber
                  control={control}
                  percentage={false}
                  placeholder="R$ 0,00"
                  label={"Valor do saque"}
                  name={`amount`}
                  rules={{
                    required: "Campo obrigatório",
                    validate: {
                      positive: (value) =>
                        value > 0 || "O valor deve ser maior que 0",
                      max: (value) =>
                        value <= +userData.balance + +userData.amountLocked ||
                        "O valor deve ser menor que o saldo disponível",
                    },
                  }}
                />
                <Text>Apenas saldo disponível pode ser transferido.</Text>
                <Text>
                  Saldo Disponível: <b>{maskCurrency(userData.balance)}</b>
                </Text>

                <Text>
                  Saldo Bloqueado:{" "}
                  <b>{maskCurrency(userData.amountLocked)}</b>{" "}
                </Text>
                <FormControl isInvalid={!!errors.description?.message}>
                  <FormLabel display="flex" ms="4px" fontSize="sm" mb="8px">
                    Descrição<Text>*</Text>
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    {...register("description", {
                      maxLength: {
                        value: 100,
                        message: "O limite de caracteres é de 100",
                      },
                    })}
                    ms={{ base: "0px", md: "0px" }}
                    placeholder="00101"
                  />
                  <FormErrorMessage>
                    {errors.description?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </ModalBody>

            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(TransferInternal)}
                >
                  Criar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default TransferInternal;
