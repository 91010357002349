import { FormControl, FormErrorMessage, Input } from "@chakra-ui/react";
import { AsyncProps } from "chakra-react-select";
import React, { PropsWithoutRef } from "react";
import { Control, Controller, RegisterOptions } from "react-hook-form";
import FormLabelCustom from "./FormLabel";
interface InputProps
  extends PropsWithoutRef<Omit<AsyncProps<any, any, any>, `onChange`>> {
  control: Control<any>;
  label: string;
  labelProps?: any;
  type: "password" | "text" | "email" | "number" | "datetime-local" | "tel" | "date";
  name: string;
  placeholder?: string;
  beatify?: boolean;
  rules?: RegisterOptions<any>;
  onChange?: (e: string) => void;
  ref?: React.RefObject<HTMLInputElement>;
}
const InputForm: React.FC<InputProps> = ({
  control,
  label,
  rules,
  name,
  placeholder,
  type,
  beatify,
  labelProps = {},
  onChange: customOnChange,
  ref,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { onChange, name, value },
        fieldState: { invalid, error },
      }) => (
        <FormControl isInvalid={invalid}>
          <FormLabelCustom label={label} name={name} props={labelProps} />
          <Input
            name={name}
            value={value}
            ref={ref}
            {...props}
            size={beatify ? "md" : "sm"}
            onChange={(e) => {
              onChange(e);
              customOnChange && customOnChange(e.target.value);
            }}
            type={type}
            fontSize={beatify ? "md" : "md"}
            ms={{ base: "0px", md: "0px" }}
            placeholder={placeholder}
            required
            bg="white"
          />
          <FormErrorMessage>
            {error?.message || "Campo inválido"}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  );
};

export default InputForm;
