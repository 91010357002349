import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  HStack,
  Button,
  useDisclosure,
  Tooltip,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import React from "react";
import { useForm } from "react-hook-form";
import { FaLink } from "react-icons/fa";
import {
  findAllWallets,
  linkWalletToRecurrencyApi,
} from "services/api.service";

export type CreateRecurrencyForm = {
  amount: string;
  description: string;
  initDate: string;
  period: SelectOptionType;
  endDate: string;
};

const LinkWalletToRecurrency: React.FC<{
  recurrencyId: string;
  oncloseMainModal: () => void;
}> = ({ recurrencyId, oncloseMainModal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const { mutateAsync: linkWallet, isLoading: isLoadingNewRecurrence } =
    useMutation(linkWalletToRecurrencyApi);

  const { control, handleSubmit } = useForm<{
    walletId: SelectOptionType;
  }>();

  const handleLinkWallet = async ({
    walletId,
  }: {
    walletId: SelectOptionType;
  }) => {
    try {
      await linkWallet({
        id: recurrencyId,
        walletId: walletId.value,
      });
      toast({
        title: "Vinculado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: isAxiosError(error)
          ? error?.response?.data?.message
          : "Ocorreu um erro ao vincular a carteira",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Tooltip label="Link com wallet">
        <Button
          onClick={() => {
            onOpen();
          }}
          size="sm"
          rounded="sm"
          variant="outline"
          colorScheme="brand"
        >
          <FaLink />
        </Button>
      </Tooltip>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={() => {
            onClose();
            oncloseMainModal();
          }}
          size="3xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Vincular carteria</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                Você está prestes a vincular uma carteira a essa recorrência.
                <br />
                Esse link é útil para pagamentos recorrentes que são programados
                diretamente com o banco. <br />
                Caso não aparece uma carteira para você vincular, solicite ao
                administrador do sistema.
              </Text>
              <FormRemoteSelectInput
                control={control}
                label="Carteira"
                noOptionsMessage={() => {
                  return <>Nenhuma opção encontrada. </>;
                }}
                registerOptions={{
                  required: "a seleção da carteira é obrigatório",
                }}
                loadDataFn={({ value, cb }) =>
                  findAllWallets({
                    filter: value,
                    page: 0,
                    limit: 10,
                  }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb(
                        retorno.registers?.map((d: any) => ({
                          label: `${
                            d.type === "DICT" ? "Chave Pix" : "QrCode"
                          } - ${d.txId}`,
                          value: d.id,
                        }))
                      );
                    } else {
                      cb([]);
                    }
                  })
                }
                name="walletId"
              />
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    onClose();
                    oncloseMainModal();
                  }}
                >
                  Fechar
                </Button>
                <Button
                  onClick={handleSubmit(handleLinkWallet)}
                  colorScheme="green"
                  isLoading={isLoadingNewRecurrence}
                >
                  Realizar vínculo
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default LinkWalletToRecurrency;
