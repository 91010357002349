import {
  Badge,
  Heading,
  HStack,
  IconButton,
  SimpleGrid,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Card from "components/card/Card";
import TableComponent from "components/TableComponent";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { MdBlock } from "react-icons/md";
import { findAllMovementsCard, findCards } from "services/api.service";
import { maskCurrency } from "utils/number";
import { PiList } from "react-icons/pi";
import CustomButton from "components/CustomButton";

enum TypeDescrition {
  RECHARGE = "Recarga",
  BUY = "Compra",
}

export default function CardsPage() {
  const [cardId, setCardId] = useState<string | null>(null);

  const { data: cards } = useQuery(["cards"], async () =>
    findCards({
      limit: 10,
      page: 0,
    })
  );

  const columns = useMemo(
    () => [
      {
        Header: "Descrição",
        accessor: "description",
      },
      {
        Header: "Valor",
        accessor: "amount",
        Cell: ({ value }: { value: number }) => maskCurrency(value),
      },
      {
        Header: "Data",
        accessor: "createdAt",
        Cell: ({ value }: { value: string }) =>
          DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        Header: "Origem",
        accessor: "movementType",
        Cell: ({ value }: { value: string }) => (
          <Badge colorScheme="green">{TypeDescrition[value]}</Badge>
        ),
      },
    ],
    []
  );

  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        px={5}
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Heading mb={5} fontWeight="semibold" fontSize="lg">
          Cartões
        </Heading>
        <SimpleGrid mt={2} columns={{ sm: 1, md: 2, lg: 3 }} gap={3}>
          {cards?.registers.map((card) => (
            <Card
              key={card.id}
              flexDirection="column"
              w="100%"
              background={!card.status ? "red.100" : "green.50"}
              px={5}
              border="1px solid #e2e8f0"
              overflowX={{ sm: "scroll", lg: "hidden" }}
            >
              <HStack justifyContent="space-between">
                <VStack alignItems="flex-start">
                  <Heading mb={5} fontSize="lg">
                    Cartão {card.finalNumber} {!card.status && "Cancelado"}
                  </Heading>
                  <Text>Nome: {card.description}</Text>
                  <Text>Saldo: <b>{maskCurrency(card.balance)}</b></Text>
                </VStack>
                {card.status ? (
                  <VStack alignItems="flex-end">
                    <CustomButton
                      onClick={() => {
                        setCardId(card.id);
                      }}
                      colorScheme="green"
                      leftIcon={<PiList />}
                    >
                      Lançamentos
                    </CustomButton>
                  </VStack>
                ) : (
                  <VStack alignItems="flex-end">
                    <Tooltip label="Cartão bloqueado">
                      <IconButton
                        aria-label="Cartão bloqueado"
                        colorScheme="red"
                        size="sm"
                        ml={3}
                        variant="ghost"
                        rounded={"sm"}
                        icon={<MdBlock />}
                      />
                    </Tooltip>
                    <CustomButton
                      isDisabled={card.status}
                      onClick={() => {
                        setCardId(card.id);
                      }}
                      colorScheme="green"
                      leftIcon={<PiList />}
                    >
                      Lançamentos
                    </CustomButton>
                  </VStack>
                )}
              </HStack>
            </Card>
          ))}{" "}
        </SimpleGrid>

        {cardId && (
          <TableComponent
            label={`Lançamentos do cartão ${
              cards?.registers.find((card) => card.id === cardId)?.finalNumber
            }`}
            columnsData={columns}
            queryFn={(qb: any) =>
              findAllMovementsCard({ ...qb, cardId: cardId })
            }
            queryKey={`findAllCards${cardId}`}
            actions={() => {}}
          />
        )}
      </Card>
    </>
  );
}
