import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import RecoverPasswordForm from "views/admin/customers/components/RecoverPassCard";

const ForgetPassword: React.FC<{}> = () => {
  const { onOpen, isOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        rounded="sm"
        variant="link"
        color="brand.900"
      >
        Esqueci minha senha
      </Button>
      {isOpen && (
        <Portal>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Esqueci minha senha</ModalHeader>
              <ModalCloseButton />
              <ModalBody pb={5}>
                <RecoverPasswordForm onCloseModal={onClose} />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  );
};

export default ForgetPassword;
