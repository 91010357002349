// Chakra imports
import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { QRCodeCanvas } from "qrcode.react";
import { Skeleton } from "@chakra-ui/react";
import {
  getChargeFromPublic,
  registerBilletForCharge,
} from "services/api.service";
import { maskCurrency } from "utils/number";
import { DateTime } from "luxon";
import { StatusRender } from "views/admin/charges";
import { GrClose } from "react-icons/gr";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useCallback, useEffect, useState } from "react";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";
import MercadoPago from "./MercadoPago";
import PagSeguroCard from "./PagSeguroCard";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { ChargePublic } from "./types/ChargeTypes";
import { CopyIcon } from "@chakra-ui/icons";
import { getVariable } from "whitelabel";

const LabelPix: React.FC<{ amount: string; qrCode: string }> = ({
  amount,
  qrCode,
}) => {
  const toast = useToast();

  if (!qrCode) {
    return (
      <TabPanel textAlign="center">
        <Text fontSize={30} mb="2">
          Valor: <b>{maskCurrency(amount)}</b>
        </Text>
        <Text fontWeight="bold" mt={6} mb="2">
          QRcode Pix para pagamento
        </Text>
        <Text fontSize="sm">
          QRCode não gerado. Por favor, entre em contato com o vendedor.
        </Text>
      </TabPanel>
    );
  }
  return (
    <TabPanel textAlign="center">
      <Flex flexDirection="column" alignItems="center">
        <Text fontSize={30} mb="2">
          Valor: <b>{maskCurrency(amount)}</b>
        </Text>
        <Text fontWeight="bold" mb="2">
          QRcode Pix para pagamento
        </Text>
        <Stack direction="row" spacing={4}>
          <Box
            backgroundColor="whiteAlpha.600"
            borderWidth="1px"
            borderRadius="lg"
            padding={5}
            width="100%"
            maxW="250px"
          >
            <QRCodeCanvas size={200} value={qrCode} />
          </Box>
          <Text justifyContent="flex-start" textAlign="left" fontSize="sm">
            <b> O que é o Pix?</b>
            <br /> O Pix é a nova modalidade de transferências do banco central,
            que funcionam 24 horas por dia e possuem confirmação em tempo real.
            <br />
            Procure em seu aplicativo de banco ou conta digital a funcionalidade
            e escaneie o QR Code ao lado ou copie o código usando o Pix Copia e
            Cola para efetuar um pagamento.
          </Text>
        </Stack>
        <Button
          mt={6}
          mb="2"
          size="sm"
          colorScheme="brand"
          variant="outline"
          leftIcon={<Icon as={CopyIcon} />}
          onClick={() => {
            navigator.clipboard.writeText(qrCode);
            toast({
              title: "QRCode copiado para área de transferência",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }}
        >
          Pix Copia e Cola
        </Button>
      </Flex>
    </TabPanel>
  );
};

const ChargeMethods: React.FC<{
  charge: ChargePublic;
  loadCharge: () => {};
}> = ({ charge, loadCharge }) => {
  const toast = useToast();
  const ga = useAnalyticsEventTracker(`charge/:id`);
  const [tabIndex, setTabIndex] = useState(0);

  const [loadingGenBillet, setLoadingGenBillet] = useState(false);
  const [showMercadoPago, setShowMercadoPago] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(
    async (type = "REGISTER_MERCADOPAGO") => {
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      return await executeRecaptcha(type);
      // Do whatever you want with the token
    },
    [executeRecaptcha]
  );

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const handleGenerateBillet = async () => {
    ga(`gerar boleto`);
    setLoadingGenBillet(true);
    const token = await handleReCaptchaVerify("REGISTER_BILLET");

    registerBilletForCharge(charge.id, token)
      .then(() => {
        setLoadingGenBillet(false);
        toast({
          title: "Boleto gerado com sucesso",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        loadCharge();
      })
      .catch((err) => {
        ga(`gerar boleto`, `erro`);

        setLoadingGenBillet(false);
        toast({
          title: err?.response?.data?.message ?? "Erro ao gerar boleto",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <Tabs
      position="relative"
      w="100%"
      index={tabIndex}
      onChange={handleTabsChange}
    >
      <TabList>
        {charge.features.pix && (
          <Tab
            key="Pix"
            _selected={{ color: "white", bg: "brand.400" }}
            _focus={{ boxShadow: "none" }}
            _active={{ bgColor: "brand.400" }}
            _activeLink={{ color: "white", bgColor: "brand.400" }}
            _peerActive={{ color: "white", bgColor: "brand.400" }}
            _selection={{ color: "white", bgColor: "brand.400" }}
            onClick={() => {
              setShowMercadoPago(false);
            }}
          >
            Pix
          </Tab>
        )}
        {charge.features.billet && (
          <Tab
            key="Boleto"
            _selected={{ color: "white", bg: "brand.400" }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              setShowMercadoPago(false);
            }}
          >
            Boleto
          </Tab>
        )}
        {charge.features.pagSeguro && (
          <Tab
            key="Pagseguro"
            _selected={{ color: "white", bg: "brand.400" }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              setShowMercadoPago(false);
            }}
          >
            Cartão de Crédito
          </Tab>
        )}
        {charge.features.mercadoPago && (
          <Tab
            key="MercadoPago"
            onClick={() => {
              setShowMercadoPago(true);
            }}
            _selected={{ color: "white", bg: "brand.400" }}
            _focus={{ boxShadow: "none" }}
          >
            Mercado Pago
          </Tab>
        )}
      </TabList>
      <TabIndicator
        mt="-1.5px"
        height="2px"
        bg="brand.800"
        borderRadius="1px"
      />
      <TabPanels>
        {charge.features.pix && (
          <LabelPix amount={charge.amount} qrCode={charge.Pix[0]?.qrCode} />
        )}
        {charge.features.billet && (
          <TabPanel textAlign="center">
            <Text fontSize={30} mb="2">
              Valor: <b>{maskCurrency(charge.amount)}</b>
            </Text>
            <Text fontWeight="bold" mt={6} mb="2">
              Boleto para pagamento.
            </Text>
            {charge.Billet.length > 0 ? (
              <>
                <Button
                  mb="2"
                  size="sm"
                  colorScheme="brand"
                  onClick={() => {
                    ga(`copiar codigo de barras`);
                    navigator.clipboard.writeText(charge.Billet[0].billetDigit);
                    toast({
                      title: "Boleto copiado para área de transferência",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                    });
                  }}
                >
                  Copiar código de barras
                </Button>
                <Link
                  colorScheme="brand"
                  href={charge.billetPdf}
                  target="_blank"
                >
                  Abrir boleto para pagamento
                </Link>
              </>
            ) : (
              <>
                <Text fontWeight="bold" mt={6} mb="2">
                  Boleto não gerado.
                </Text>
                <Button
                  size="sm"
                  colorScheme="brand"
                  isLoading={loadingGenBillet}
                  onClick={handleGenerateBillet}
                >
                  Gerar Boleto
                </Button>
              </>
            )}
          </TabPanel>
        )}
        {charge.features.pagSeguro && (
          <TabPanel textAlign="center" maxW="450px">
            <Text fontSize={30} mb="2">
              Valor: <b>{maskCurrency(+charge.amount)}</b>
            </Text>
            <PagSeguroCard
              id={charge.id}
              amount={+charge.amount}
              installmentsConfig={charge.installmentsConfig}
            />
          </TabPanel>
        )}
        {charge.features.mercadoPago && (
          <TabPanel textAlign="center" maxW="450px">
            <Text fontSize={30} mb="2">
              Valor: <b>{maskCurrency(+charge.amount * 1.05)}</b>
            </Text>{" "}
            {showMercadoPago && (
              <MercadoPago id={charge.id} amount={+charge.amount * 1.05} />
            )}
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
};

const ItemText: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <HStack mb={2}>
    <Badge size="xs">
      <Text fontWeight={"thin"}>{label}</Text>
    </Badge>
    <Text>{value}</Text>
  </HStack>
);

// Custom Chakra theme
export default function Charge({ match: { params } }) {
  // states and functions
  const chargeId = params.id;
  const [bill, setBill] = useState<ChargePublic>(null);
  const [isError, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadCharge = useCallback(async () => {
    try {
      const data = await getChargeFromPublic(chargeId);

      if (data?.status !== bill?.status) {
        setBill(data);
        setIsLoading(false);
      }
    } catch (err) {
      setError(err);
      setIsLoading(false);
    }
  }, [bill?.status, chargeId]);
  useEffect(() => {
    loadCharge();

    const interval = setInterval(() => {
      loadCharge();
    }, 10000);

    return () => clearInterval(interval);
  }, [loadCharge]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
      <VStack justifyContent={"flex-start"} mt={5}>
        <HStack
          w={{
            base: "100%",
            md: "90%",
            lg: "80%",
            xl: "70%",
          }}
          justifyContent="space-between"
        >
          <HStack>
            <Image src={getVariable("logo")} width="200px" />
            <Text fontSize={32} fontWeight="bold">
              FATURA <StatusRender status={bill?.status} />
            </Text>
          </HStack>
          <Box
            textAlign="right"
            alignItems="flex-end"
            justifyContent="flex-end"
            flexDirection="column"
          >
            <Text fontSize={"xs"}>DATA DE EMISSÃO: </Text>
            {bill?.createdAt
              ? DateTime.fromISO(bill.createdAt).toFormat("dd/MM/yyyy HH:mm")
              : "Não informado"}
          </Box>
        </HStack>
        {!isLoading && bill ? (
          <Stack
            backgroundColor="whiteAlpha.600"
            borderWidth="1px"
            borderRadius="lg"
            margin={{
              base: "2",
              md: "4",
              lg: "6",
              xl: "2",
            }}
            width={{
              base: "100%",
              md: "90%",
              lg: "80%",
              xl: "70%",
            }}
            alignItems="flex-start"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <VStack p="4" alignItems="flex-start" width="100%">
              <Text fontSize="xl">
                <b>Informações da cobrança</b>
              </Text>
              <HStack width="100%" justifyContent="" alignItems="center" mb={2}>
                <Badge size="xs" fontWeight="thin">
                  Identificador:
                </Badge>
                <Text fontSize="xs">#{bill.id}</Text>
              </HStack>
              <ItemText label="Valor: " value={maskCurrency(bill.amount)} />
              <ItemText
                label="Data de vencimento: "
                value={
                  bill.expireAt
                    ? DateTime.fromISO(bill.expireAt).toFormat(
                        "dd/MM/yyyy HH:mm"
                      )
                    : "Não informado"
                }
              />
              <ItemText label="Outras informações: " value={bill.description} />

              <Text fontSize="lg">
                <b>Dados do cliente</b>
              </Text>
              <ItemText label="Nome: " value={bill.person?.name} />
              {bill.person?.email !== "not_found" && (
                <ItemText label="Email: " value={bill.person?.email} />
              )}

              {bill.person?.document && (
                <ItemText label="CPF/CNPJ: " value={bill.person?.document} />
              )}

              {bill.shipping && (
                <ItemText
                  label="Endereço: "
                  value={`
                ${bill.shipping?.street}, ${bill.shipping?.number}  ${
                    bill.shipping?.complement
                      ? `, ${bill.shipping?.complement}`
                      : ""
                  } - ${bill.shipping?.district} - ${bill.shipping?.city} - ${
                    bill.shipping?.state
                  } - ${bill.shipping?.zipCode}
                  `}
                />
              )}

              {bill.itens?.length > 0 && (
                <Text fontSize="xl">
                  <b>Itens da cobrança</b>
                </Text>
              )}
              <TableContainer width="100%">
                <Table size="sm" variant="striped" colorScheme="brand">
                  <Thead>
                    <Tr>
                      <Th>Descrição</Th>
                      <Th>Qtd</Th>
                      <Th isNumeric>Valor final</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {bill.itens.map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.name}</Td>
                        <Td>{item.quantity}</Td>
                        <Td isNumeric>{maskCurrency(item.amount)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                  <Tfoot>
                    <Tr>
                      <Th>Total </Th>
                      <Th></Th>
                      <Th isNumeric>
                        {maskCurrency(
                          bill.itens.reduce(
                            (acc, item) => acc + +item.amount,
                            0
                          )
                        )}
                      </Th>
                    </Tr>
                    <Tr>
                      <Th>Desconto</Th>
                      <Th></Th>
                      <Th isNumeric>
                        {maskCurrency(
                          bill.itens.reduce(
                            (acc, item) => acc + +item.amount,
                            0
                          ) - +bill.amount
                        )}
                      </Th>
                    </Tr>
                    <Tr>
                      <Th>Valor final</Th>
                      <Th></Th>
                      <Th isNumeric>{maskCurrency(bill.amount)}</Th>
                    </Tr>
                  </Tfoot>
                </Table>
              </TableContainer>
            </VStack>
            <VStack width="100%" height="100%">
              <Flex
                key={bill.id}
                width="100%"
                height="100%"
                alignItems="center"
                flexDirection="column"
              >
                <>
                  {bill.status === "CANCELED" ? (
                    <>
                      <Icon fontSize={130} color="red.400" as={GrClose}></Icon>
                      <Text fontWeight="bold" fontSize={24} mb="2">
                        Cancelado
                      </Text>
                    </>
                  ) : bill.status === "PENDING" ? (
                    <ChargeMethods charge={bill} loadCharge={loadCharge} />
                  ) : (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      width="100%"
                      direction="column"
                    >
                      <Icon
                        fontSize={130}
                        color="green.400"
                        as={AiOutlineCheckCircle}
                      ></Icon>
                      <Text fontWeight="bold" fontSize={24} mb="2">
                        Pago
                      </Text>
                    </Stack>
                  )}
                </>
              </Flex>
            </VStack>
          </Stack>
        ) : isError ? (
          <Stack mt={10} width="500px">
            <Text>Erro ao carregar cobrança</Text>
          </Stack>
        ) : (
          <Stack mt={10} width="500px">
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </Stack>
        )}
        <Button
          mt="6"
          size="sm"
          colorScheme="brand"
          onClick={() => {
            loadCharge();
          }}
        >
          Atualizar
        </Button>
      </VStack>
    </GoogleReCaptchaProvider>
  );
}
