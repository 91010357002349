import {
  Divider,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
// Custom components
import Card from "components/card/Card";
import FileSaver from "file-saver";
import React from "react";
import { useForm } from "react-hook-form";
import { FaUpload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useDebounce } from "react-use";
import { findAllTerminals } from "services/api.service";
import FormRemoteSelectInput from "./FormRemoteSelectInput";
import InputForm from "./InputForm";
import TableList from "./Table";
import SelectForm from "./SelectForm";

export default function TableComponent(props: {
  queryFn: Function;
  columnsData: any[];
  exportPdf?: boolean;
  queryKey: string;
  filterable?: string[];
  actions: any;
  right?: any;
  setSelectedRows?: any;
  enableSelect?: boolean;
  label?: string;
}) {
  const toast = useToast();

  const [filter, setFilter] = React.useState<{
    pageIndex: number;
    pageSize: number;
    orderBy: any[];
    filter: string;
    customerId?: string;
    maquinetaId?: string;
    companyId?: string;
    initialDate?: string;
    finalDate?: string;
    query?: Record<string, any>;
  }>({
    pageIndex: 0,
    pageSize: 20,
    orderBy: [],
    filter: "",
  });

  const loc = useLocation();
  const {
    columnsData,
    queryFn,
    queryKey,
    actions,
    right,
    filterable,
    setSelectedRows,
    enableSelect,
    exportPdf,
    label,
  } = props;

  const { control } = useForm();

  React.useEffect(() => {
    const params = new URLSearchParams(loc.search);

    const fil: any = {};
    filterable?.map((f) => {
      if (params.get(f)) {
        fil[f] = params.get(f);
      }
      return f;
    });

    setFilter((prev) => ({
      ...prev,
      ...fil,
    }));
  }, [filterable, loc.search]);

  const [busca, setBusca] = React.useState("");

  useDebounce(
    () => {
      setFilter((prev) => ({ ...prev, filter: busca }));
    },
    500,
    [busca]
  );

  const { data, isLoading } = useQuery<{
    pages: number;
    registers: any[];
  }>(
    [queryKey, filter],
    () =>
      queryFn({
        page: filter.pageIndex,
        filter: filter.filter,
        customerId: filter.customerId,
        companyId: filter.companyId,
        maquinetaId: filter.maquinetaId,
        limit: filter.pageSize,
        orderBy: filter.orderBy?.[0]?.id,
        direction: filter.orderBy?.[0]?.desc ? "desc" : "asc",
        initialDate: filter.initialDate,
        finalDate: filter.finalDate,
        query: filter.query,
      }),
    {
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Card flexDirection="column" w="100%" p={0} pt={5} pb={3} px="0px">
        <Text fontSize="lg" fontWeight="bold" px={5}>
          {label}
        </Text>
        <HStack
          px={5}
          justifyContent="space-between"
          flexDirection={{
            base: "column",
            md: "row",
          }}
        >
          <SimpleGrid
            spacing={5}
            width="100%"
            alignContent="center"
            alignItems="flex-end"
            mb={{
              base: 2,
              md: 3,
            }}
            columns={{
              base: 1,
              md: 3,
              lg: 6,
            }}
          >
            <InputForm
              placeholder="Filtrar por palavras chaves..."
              control={control}
              name="filter"
              onChange={(value) => setBusca(value)}
              label="Filtrar"
              type="text"
              size="sm"
            />
            {columnsData
              .filter((item) => item.filter)
              .map((itemColumn) => {
                return (
                  <>
                    <SelectForm
                      placeholder={`Filtrar por ${itemColumn.Header}...`}
                      control={control}
                      name={itemColumn.accessor}
                      label={itemColumn.Header}
                      onChange={(value) =>
                        setFilter((prev) => ({
                          ...prev,
                          query: {
                            ...prev.query,
                            [itemColumn.accessor]: value,
                          },
                        }))
                      }
                      options={itemColumn.values.map((key) => ({
                        label: key.label,
                        value: key.value,
                      }))}
                    />
                  </>
                );
              })}

            {filterable?.includes("createdAt") && (
              <>
                <InputForm
                  placeholder="Filtrar por palavras chaves..."
                  control={control}
                  name="initialDate"
                  label="Data Inicial"
                  type="datetime-local"
                  size="sm"
                  onChange={(value) =>
                    setFilter((prev) => ({ ...prev, initialDate: value }))
                  }
                />
                <InputForm
                  placeholder="Filtrar por palavras chaves..."
                  control={control}
                  name="finalDate"
                  label="Data Final"
                  type="datetime-local"
                  size="sm"
                  onChange={(value) =>
                    setFilter((prev) => ({ ...prev, finalDate: value }))
                  }
                />
              </>
            )}

            {filterable?.includes("maquinetaId") && (
              <FormRemoteSelectInput
                control={control}
                name="maquineta"
                label="Terminal"
                size="sm"
                onChange={(e) => {
                  setFilter((prev) => ({ ...prev, maquinetaId: e.value }));
                }}
                loadDataFn={({ value, cb }) =>
                  findAllTerminals({
                    filter: value,
                    limit: 10,
                    page: 0,
                    orderBy: [
                      {
                        id: "name",
                        desc: false,
                      },
                    ],
                  }).then((retorno) => {
                    if (retorno?.registers?.length > 0) {
                      cb([
                        { label: "Escolher uma opção...", value: "" },
                        ...retorno.registers?.map((d: any) => ({
                          label: `${d.number} - ${d.name}`,
                          value: d.id,
                        })),
                      ]);
                    } else {
                      cb([]);
                    }
                  })
                }
              />
            )}
            {exportPdf && (
              <IconButton
                aria-label="Exportar"
                rounded="md"
                size="sm"
                w={10}
                variant="outline"
                onClick={() => {
                  if (!filter.initialDate || !filter.finalDate) {
                    toast({
                      title: "Erro",
                      description: "Selecione uma data inicial e final",
                      status: "error",
                      duration: 9000,
                      position: "top",
                      isClosable: true,
                    });
                    return;
                  }

                  queryFn(
                    {
                      page: filter.pageIndex,
                      filter: filter.filter,
                      customerId: filter.customerId,
                      companyId: filter.companyId,
                      maquinetaId: filter.maquinetaId,
                      limit: filter.pageSize,
                      orderBy: filter.orderBy?.[0]?.id,
                      direction: filter.orderBy?.[0]?.desc ? "desc" : "asc",
                      initialDate: filter.initialDate,
                      finalDate: filter.finalDate,
                    },
                    true
                  ).then((res: any) => {
                    FileSaver(res, "relatorio.pdf");
                  });
                }}
                icon={<FaUpload />}
              />
            )}
          </SimpleGrid>
          {right}
        </HStack>
        <Divider mt={5} />
        {isLoading ? (
          <Flex
            justify="center"
            align="center"
            h="100%"
            w="100%"
            minH={{ sm: "100px", lg: "200px" }}
            color="gray.500"
          >
            <Text>Carregando...</Text>
          </Flex>
        ) : (
          <TableList
            actions={actions}
            columnsData={columnsData}
            itensData={data?.registers || []}
            pageCount={data?.pages || 1}
            setFilter={setFilter}
            enableSelect={enableSelect}
            setSelectedRows={setSelectedRows}
          />
        )}
      </Card>
    </>
  );
}
