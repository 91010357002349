import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import FormRemoteSelectInput, {
  SelectOptionType,
} from "components/FormRemoteSelectInput";
import { queryClient } from "index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import {
  addClientToRecurrence,
  findAllClients,
} from "services/api.service";

interface AddForm {
  clientId: SelectOptionType;
}

const AddClientToRecurrence: React.FC<{ recurrenceId: string }> = ({
  recurrenceId,
}) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<AddForm>();

  const save = async (dataForm: AddForm) => {
    setLoading(true);
    addClientToRecurrence(recurrenceId, {
      clientId: dataForm.clientId.value,
    })
      .then(() => {
        onClose();
        queryClient.refetchQueries({
          queryKey: ["getRecurrence", recurrenceId],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Recorrência adicionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description:
            err?.response?.data?.message || "Erro ao criar Recorrência",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPlus />
        <Text ml={2}>Vincular Cliente</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Recorrência</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <FormRemoteSelectInput
                  control={control}
                  label="Pagador"
                  noOptionsMessage={() => {
                    return <>Nenhuma opção encontrada. </>;
                  }}
                  registerOptions={{
                    required: "a seleção do pagador é obrigatório",
                  }}
                  loadDataFn={({ value, cb }) =>
                    findAllClients({ filter: value, limit: 20, page: 0 }).then(
                      (retorno) => {
                        if (retorno?.registers?.length > 0) {
                          cb(
                            retorno.registers?.map((d: any) => ({
                              label: `${d.name} - ${d.document}`,
                              value: d.id,
                            }))
                          );
                        } else {
                          cb([]);
                        }
                      }
                    )
                  }
                  name="clientId"
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AddClientToRecurrence;
