import React from "react";
import "./assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin";
import RTLLayout from "./layouts/rtl";
import { Box, ChakraProvider, Tooltip } from "@chakra-ui/react";
import theme from "./theme/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactDOM from "react-dom/client";
import MasterContext from "context";
import ReactGA from "react-ga4";
import Charge from "layouts/charge";
import { FaWhatsapp } from "react-icons/fa";
import { getVariable } from "whitelabel";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

export const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ChakraProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <React.StrictMode>
        <MasterContext>
          <Box
            position="fixed"
            bottom="20px"
            left="20px"
            zIndex="9999"
            onClick={() => {
              window.open(
                getVariable("supportWhatsapp"),
                "_blank"
              );
            }}
            p="15px"
            bg="green.500"
            borderRadius="50%"
            cursor="pointer"
            color="white"
          >
            <Tooltip
              label="Entre em contato conosco"
              aria-label="Entre em contato conosco"
            >
              <FaWhatsapp />
            </Tooltip>
          </Box>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <Route path={`/admin`} component={AdminLayout} />
              <Route path={`/rtl`} component={RTLLayout} />
              <Route path={`/charge/:id`} component={Charge} />
              <Redirect from="/" to="/auth" />
            </Switch>
          </HashRouter>
        </MasterContext>
      </React.StrictMode>
    </QueryClientProvider>
  </ChakraProvider>
);
