import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import FormLabelCustom from "components/FormLabel";
import FormRemoteSelectInput from "components/FormRemoteSelectInput";
import useAuth from "contexts/useAuth";
import React from "react";
import { useForm } from "react-hook-form";
import {
  createWithdrawalAntecipate,
  findAllParticipants,
  getAccounts,
  getAntecipationValue,
} from "services/api.service";
import { maskCurrency } from "utils/number";
import { z } from "zod";

export const chaveSchema = z.union([
  z.string().regex(/^\d+$/).length(11),
  z.string().regex(/^\d+$/).length(14),
  z.string().regex(/^\+[1-9]\d\d{1,14}$/),
  z
    .string()
    .regex(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    ),
  z
    .string()
    .regex(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)
    .length(36),
]);

const AntecipateWithdrawel: React.FC = () => {
  const toast = useToast();
  const { updateUserData } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [type, setType] = React.useState<"DICT" | "MANU">("DICT");
  const [accountId, setAccountId] = React.useState<string | undefined>();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<{
    ispb: string;
    keyPix: string;
    amount: number;
    account: string;
    description: string;
    agency: string;
  }>();

  const { isLoading, data: antecipationData } = useQuery(
    ["getAntecipationValue"],
    () => getAntecipationValue(),
    {
      enabled: isOpen,
      onSuccess: (data) => {
        setValue("amount", data.amount_total);
      },
    }
  );

  const createwithdrawel = async (data: any) => {
    setLoading(true);
    try {
      await createWithdrawalAntecipate({
        ...data,
        customerAccountId: accountId,
        ispb: data.ispb?.value,
        type,
      });
      updateUserData();
      toast({
        title: "Sucesso",
        description: "Saque criado com sucesso",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error: any) {
      toast({
        title: "Erro",
        description: error?.response?.data?.message || "Erro ao criar saque",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        colorScheme="brand"
        onClick={onOpen}
        size="sm"
        mb={2}
        width={{ base: "100%", md: "auto" }}
        rounded="sm"
        px={5}
        variant="outline"
      >
        Antecipar
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Antecipação de Valores</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {isLoading ? (
                <>
                  <Text>Carregando...</Text>
                </>
              ) : (
                <>
                  {+(antecipationData?.amount ?? 0) === 0 ? (
                    <Text>Sem saldo disponível</Text>
                  ) : (
                    <VStack spacing={5} alignItems="start">
                      <Text fontWeight="semibold">Dados bancários</Text>

                      <FormRemoteSelectInput
                        control={control}
                        label="Conta cadastrada?"
                        onChange={(e) => {
                          setAccountId(e?.value);
                        }}
                        loadDataFn={({ value, cb }) =>
                          getAccounts({ filter: value }).then((retorno) => {
                            if (retorno?.registers?.length > 0) {
                              cb(
                                retorno.registers?.map((d: any) => ({
                                  label: d.pix || d.agency + " - " + d.account,
                                  value: d.id,
                                }))
                              );
                            } else {
                              cb([]);
                            }
                          })
                        }
                        name="accountId"
                      />
                      <FormLabelCustom
                        label="Tipo de transfêrencia"
                        props={undefined}
                        name={""}
                      />
                      <Select
                        onChange={(e) => {
                          setType(e.target.value as any);
                        }}
                        disabled={!!accountId}
                      >
                        <option value="DICT">Chave Pix</option>
                        <option value="MANU">Dados manuais</option>
                      </Select>

                      {type === "DICT" && !accountId && (
                        <>
                          <Text fontWeight="bold">
                            A chave deve pertencer ao mesmo CNPJ do cadastro.
                          </Text>
                          <FormControl isInvalid={!!errors.keyPix?.message}>
                            <FormLabel
                              display="flex"
                              ms="4px"
                              fontSize="sm"
                              mb="8px"
                            >
                              Chave Pix (Opcional)
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              name="pix"
                              {...register("keyPix", {
                                required:
                                  !!accountId || "Esse campo é obrigatório",
                                validate: (value) => {
                                  if (!chaveSchema.safeParse(value).success) {
                                    return "Chave inválida";
                                  }
                                },
                              })}
                              ms={{ base: "0px", md: "0px" }}
                              type="text"
                              placeholder="XXXXXXXXXXXXXXXXXXXX"
                              isDisabled={!!accountId}
                            />
                            <FormErrorMessage>
                              {errors.keyPix?.message}
                            </FormErrorMessage>
                          </FormControl>
                        </>
                      )}

                      {type === "MANU" && !accountId && (
                        <>
                          <FormRemoteSelectInput
                            control={control}
                            label="Instituição financeira"
                            required
                            isDisabled={!!accountId}
                            registerOptions={{
                              required:
                                !!accountId || "Esse campo é obrigatório",
                            }}
                            loadDataFn={({ value, cb }) =>
                              findAllParticipants({ filter: value }).then(
                                (retorno) => {
                                  if (retorno?.registers?.length > 0) {
                                    cb(
                                      retorno.registers?.map((d: any) => ({
                                        label: d.reducedName,
                                        value: d.ispb,
                                      }))
                                    );
                                  } else {
                                    cb([]);
                                  }
                                }
                              )
                            }
                            name="ispb"
                          />
                          <FormControl isInvalid={!!errors.agency?.message}>
                            <FormLabel
                              display="flex"
                              ms="4px"
                              fontSize="sm"
                              mb="8px"
                            >
                              Agência<Text>*</Text>
                            </FormLabel>
                            <Input
                              isRequired={true}
                              variant="auth"
                              fontSize="sm"
                              maxLength={4}
                              isDisabled={!!accountId}
                              name="agency"
                              {...register("agency", {
                                required: !!accountId,
                                minLength: {
                                  message:
                                    "Esse campo deve ter no mínimo 4 caracteres",
                                  value: 4,
                                },
                                maxLength: {
                                  message:
                                    "Esse campo deve ter no máximo 4 caracteres",
                                  value: 4,
                                },
                              })}
                              ms={{ base: "0px", md: "0px" }}
                              type="number"
                              placeholder="0001"
                              required
                            />
                            <FormErrorMessage>
                              {errors.agency?.message}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl isInvalid={!!errors.account?.message}>
                            <FormLabel
                              display="flex"
                              ms="4px"
                              fontSize="sm"
                              mb="8px"
                            >
                              Conta (com dígito)<Text>*</Text>
                            </FormLabel>
                            <Input
                              isRequired={true}
                              variant="auth"
                              isDisabled={!!accountId}
                              fontSize="sm"
                              name="account"
                              {...register("account", {
                                required:
                                  !!accountId || "Esse Campo é Obrigatório",
                              })}
                              ms={{ base: "0px", md: "0px" }}
                              type="tel"
                              placeholder="00101"
                            />
                            <FormErrorMessage>
                              {errors.account?.message}
                            </FormErrorMessage>
                          </FormControl>
                        </>
                      )}

                      <Text>
                        Valor Disponível:{" "}
                        <b>{maskCurrency(+antecipationData.amount_total)}</b>
                      </Text>
                      <Text>
                        Saldo que será pago:{" "}
                        <b>{maskCurrency(antecipationData.amount)}</b>
                      </Text>

                      <FormControl isInvalid={!!errors.description?.message}>
                        <FormLabel
                          display="flex"
                          ms="4px"
                          fontSize="sm"
                          mb="8px"
                        >
                          Descrição<Text>*</Text>
                        </FormLabel>
                        <Input
                          isRequired={true}
                          variant="auth"
                          fontSize="sm"
                          {...register("description", {
                            maxLength: {
                              value: 100,
                              message: "O limite de caracteres é de 100",
                            },
                          })}
                          ms={{ base: "0px", md: "0px" }}
                          type="tel"
                          placeholder="00101"
                        />
                        <FormErrorMessage>
                          {errors.description?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </VStack>
                  )}
                </>
              )}
            </ModalBody>

            <ModalFooter>
              {+(antecipationData?.amount ?? 0) > 0 && (
                <HStack spacing={4}>
                  <Button
                    colorScheme="green"
                    isLoading={loading}
                    onClick={handleSubmit(createwithdrawel)}
                  >
                    Criar
                  </Button>
                </HStack>
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default AntecipateWithdrawel;
