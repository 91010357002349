// Chakra imports
import {
  Box,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import LoginInputs from "./LoginInputs";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { getVariable } from "whitelabel";

function SignIn() {
  const textColor = useColorModeValue("brand.900", "white");
  const textColorSecondary = "gray.400";

  return (
    <DefaultAuth
      illustrationBackground={getVariable("banner")}
      image={getVariable("banner")}
    >
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA}>
        <Flex
       
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          my={{ base: "auto", lg: "150" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "0", md: "4vh" }}
          flexDirection="column"
        >
          <Box
            w="full"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Image src={getVariable("logo")} width="200px" />
            <Heading color={textColor} fontSize="24px" mb="10px" mt={10}>
              Bem vindo de volta!
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              Acesse com suas credenciais abaixo!
            </Text>
          </Box>
          <LoginInputs />
        </Flex>
      </GoogleReCaptchaProvider>
    </DefaultAuth>
  );
}

export default SignIn;
