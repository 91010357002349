// Chakra imports
import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card";
// Custom components
import LineAreaChart from "components/charts/LineAreaChart";
import { DateTime } from "luxon";
import { Daily } from "types/dashboard";

export default function WeeklyRevenue({ data }: { data: Daily[] }) {
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const lineAreaChartDataConsumption = [
    {
      name: "Pix",
      data: data?.map((item) => item.pix),
    },
    {
      name: "Dinheiro",
      data: data?.map((item) => item.cash),
    },
    {
      name: "Cartão de crédito",
      data: data?.map((item) => item.creditCard),
    },
    {
      name: "Boleto",
      data: data?.map((item) => item.ticket),
    },
    {
      name: "Cartão de Débito",
      data: data?.map((item) => item.debitCard),
    },
  ];

  const lineAreaChartOptionsConsumption = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [2, 2, 2, 2, 2],  
      curve: 'smooth',
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: data?.map((item) =>
        DateTime.fromISO(item.date).toFormat("dd/MM")
      ),
      show: false,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      color: "black",
      labels: {
        show: false,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
    },
    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: 'dark',
        gradientToColors: ['transparent'],
        shadeIntensity: 1,
        type: 'vertical',
        opacityFrom: 0.7, 
        opacityTo: 0,     
        stops: [0,100]  
      }
    },
    legend: {
      show: false,
    },
    colors: ["#5E37FF", "#6AD2FF", "#E1E9F8", "#E1a1F8", "#FFB946"],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "20px",
      },
    },
  };
  

  if (!data) {
    return;
  }

  return (
    <Card alignItems="center" flexDirection="column" w="100%">
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text
          me="auto"
          color={textColor}
          fontSize="xl"
          fontWeight="700"
          lineHeight="100%"
        >
          Vendas no Mês
        </Text>
      </Flex>

      <Box h="240px" mt="auto" w="100%">
        <LineAreaChart
          chartData={lineAreaChartDataConsumption}
          chartOptions={lineAreaChartOptionsConsumption}
        />
      </Box>
    </Card>
  );
}
