import { FormLabel, FormLabelProps } from '@chakra-ui/react';
import React, { PropsWithoutRef } from 'react';

interface FormLabelCustomProps {
  props: PropsWithoutRef<FormLabelProps>;
  label?: string;
  name: string;
}

const FormLabelCustom: React.FC<FormLabelCustomProps> = ({
  label,
  props,
  name,
}) => {
  return label ? (
    <FormLabel
      fontWeight="bold"
      fontSize="12"
      color="gray.500"
      
      id={`lbl-for-${name}`}
      htmlFor={name}
      {...props}
    >
      {label.toUpperCase()}
    </FormLabel>
  ) : null;
};

export default FormLabelCustom;
