// Chakra imports
import { Badge, Box, Flex, IconButton, SimpleGrid } from "@chakra-ui/react";

import { useMemo } from "react";
import { findAllRecurrences } from "services/api.service";
import { Column } from "react-table";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import TableComponent from "components/TableComponent";
import { FaEye } from "react-icons/fa";
import CreateRecurrence from "./components/CreateRecurrence";
import { maskCurrency } from "utils/number";

export default function Recurrences() {
  const history = useHistory();
  const columns = useMemo(
    () =>
      [
        {
          Header: "Nome",
          accessor: "description",
        },
        {
          Header: "Valor",
          accessor: "amount",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Saldo",
          accessor: "balance",
          Cell: ({ value }) => {
            return maskCurrency(value);
          },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }: { value: string }) => {
            return value ? (
              <Badge colorScheme="green">Ativo</Badge>
            ) : (
              <Badge colorScheme="red">Inativo</Badge>
            );
          },
        },
        {
          accessor: "initDate",
          Header: "Data de ínicio",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },
        {
          accessor: "endDate",
          Header: "Data de término",
          Cell: ({ value }) => {
            return value
              ? DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss")
              : "---";
          },
        },
        {
          accessor: "createdAt",
          Header: "Data",
          Cell: ({ value }) => {
            return DateTime.fromISO(value).toFormat("dd/MM/yyyy HH:mm:ss");
          },
        },
        {
          Header: "Ações",
          accessor: "actions",
        },
      ] as Column[],
    []
  );

  const actions = (row: any) => (
    <Flex align="center" justify="flex-start" w="100%">
      <IconButton
        aria-label="Apertar"
        rounded="md"
        size="sm"
        variant="outline"
        icon={<FaEye />}
        onClick={() => history.push(`/admin/recurrence/${row.id}`)}
      />
    </Flex>
  );

  // Chakra Color Mode
  return (
    <Box pt={{ base: "80px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <TableComponent
          columnsData={columns}
          right={<CreateRecurrence />}
          queryFn={findAllRecurrences}
          queryKey="findAllRecurrencys"
          actions={actions}
        />
      </SimpleGrid>
    </Box>
  );
}
