import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  HStack,
  Button,
  useToast,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import InputForm from "components/InputForm";
import SelectForm from "components/SelectForm";
import { queryClient } from "index";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaPlus } from "react-icons/fa";
import { createHolder } from "services/api.service";
import { validCpf } from "services/validDocument";
import { onlyNumbers } from "utils/number";

export type CreateHolderForm = {
  customerId: string;
  birthDate: string;
  document: string;
  createdAt: string;
  email: string;
  phone: string;
  name: string;
  gender: string;
};

const CreateHolder: React.FC<{
  onClose: () => void;
}> = ({ onClose: onCloseMain }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { handleSubmit, control, reset } = useForm<CreateHolderForm>();

  const save = async (dataForm: CreateHolderForm) => {
    setLoading(true);
    createHolder({
      ...dataForm,
    })
      .then(() => {
        onClose();
        onCloseMain();
        queryClient.refetchQueries({
          queryKey: ["findAllInsurances"],
          type: "active",
        });
        toast({
          title: "Sucesso",
          description: "Segurado adicionado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      })
      .catch((err) => {
        toast({
          title: "Erro",
          description: err?.response?.data?.message || "Erro ao criar Segurado",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    reset({});
  }, [reset]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          reset();
        }}
        size="sm"
        rounded="sm"
        variant="outline"
        colorScheme="brand"
      >
        <FaPlus />
        <Text ml={2}>Criar Segurado</Text>
      </Button>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Criar Segurado</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3}>
                <InputForm
                  control={control}
                  name="name"
                  label="Nome *"
                  type="text"
                  rules={{
                    required: "Campo obrigatório",
                  }}
                />
                <InputForm
                  control={control}
                  label="CPF *"
                  name="document"
                  size="sm"
                  isRequired={true}
                  rules={{
                    required: true,
                    validate: (value) => {
                      value = onlyNumbers(value);
                      if (validCpf(value)) return true;
                      return "Não é válido";
                    },
                  }}
                  type="tel"
                  placeholder="CPF"
                  required
                />{" "}
                <InputForm
                  control={control}
                  name="email"
                  label="Email"
                  type="email"
                />
                <InputForm
                  control={control}
                  name="birthDate"
                  placeholder="Data de nascimento"
                  label="Data de nascimento"
                  type="date"
                />
                <InputForm
                  control={control}
                  name="phone"
                  label="Telefone"
                  type="tel"
                  placeholder="Telefone"
                />
                <SelectForm
                  control={control}
                  label="Gênero"
                  name="gender"
                  options={[]}
                ></SelectForm>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack spacing={4}>
                <Button
                  colorScheme="red"
                  isLoading={loading}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  colorScheme="green"
                  isLoading={loading}
                  onClick={handleSubmit(save)}
                >
                  Adicionar
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default CreateHolder;
