import { Icon } from "@chakra-ui/react";
import {
  MdAccountBalanceWallet,
  MdBarChart,
  MdHome,
  MdLock,
  MdPin,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Orders from "views/admin/orders";

// Auth Imports
import { IoCashOutline } from "react-icons/io5";
import SignIn from "views/auth/signIn";
import Movement from "views/admin/orders/Movement";
import Withdrawals from "views/admin/withdrawals";
import Terminals from "views/admin/maquinetas";
import Balances from "views/admin/balances/balances";
import Privacy from "views/auth/signIn/privacy";
import Settings from "views/admin/customers/settings";
import Charges from "views/admin/charges";
import {
  FaFileInvoiceDollar,
  FaIdCard,
  FaReceipt,
  FaUserCircle,
} from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { BiHourglass } from "react-icons/bi";
import Charge from "views/admin/charges/Charge";
import BalancesPending from "views/admin/balances/balances-pending";
import { BsSafe2 } from "react-icons/bs";
import Insurances from "views/admin/insurances";
import Clients from "views/admin/clients";
import Recurrences from "views/admin/recurrences";
import ShowRecurrence from "views/admin/recurrences/ShowRecurrence";
import { getVariable } from "whitelabel";
import CardsPage from "views/admin/card/CardsPage";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    pathPrevious: "/#/admin/dashboard",
    namePrevious: "Início",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  {
    name: "Vendas",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/transactions",
    component: Orders,
  },
  {
    name: "Cobrança",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/charge/:id",
    hideOnSideBar: true,
    component: Charge,
  },
  ...(getVariable("recurrencyEnabled")
    ? [
        {
          name: "Clientes",
          layout: "/admin",
          icon: (
            <Icon
              as={FaUserCircle}
              width="20px"
              height="20px"
              color="inherit"
            />
          ),
          path: "/clients",
          component: Clients,
        },
        {
          name: "Recorrências",
          layout: "/admin",
          icon: (
            <Icon as={FaReceipt} width="20px" height="20px" color="inherit" />
          ),
          path: "/recurrences",
          component: Recurrences,
        },
        {
          name: "Consultar Recorrência",
          layout: "/admin",
          hideOnSideBar: true,
          icon: (
            <Icon as={FaReceipt} width="20px" height="20px" color="inherit" />
          ),
          path: "/recurrence/:id",
          component: ShowRecurrence,
        },
      ]
    : []),
  {
    name: "Cobranças",
    layout: "/admin",
    icon: (
      <Icon
        as={FaFileInvoiceDollar}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/charges",
    component: Charges,
  },
  {
    name: "Saldos",
    layout: "/admin",
    icon: (
      <Icon
        as={MdAccountBalanceWallet}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/balances",
    component: Balances,
  },

  {
    name: "Saldos Pendentes",
    layout: "/admin",
    icon: <Icon as={BiHourglass} width="20px" height="20px" color="inherit" />,
    path: "/balance-pending",
    component: BalancesPending,
  },
  {
    name: "Movimento",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/transactions/:id",
    hideOnSideBar: true,
    component: Orders,
  },

  {
    name: "Seguros",
    layout: "/admin",
    isAdmin: true,
    icon: <Icon as={BsSafe2} width="20px" height="20px" color="inherit" />,
    path: "/insurances",
    component: Insurances,
  },
  {
    name: "Saques",
    layout: "/admin",
    icon: (
      <Icon as={IoCashOutline} width="20px" height="20px" color="inherit" />
    ),
    path: "/saques",
    component: Withdrawals,
  },
  {
    name: "Venda",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/movement/:id",
    pathPrevious: "/#/admin/transactions",
    namePrevious: "Vendas",
    component: Movement,
    hideOnSideBar: true,
  },
  {
    name: "Terminais",
    layout: "/admin",
    icon: <Icon as={MdPin} width="20px" height="20px" color="inherit" />,
    path: "/terminals",
    component: Terminals,
  },
  {
    name: "Cartões",
    layout: "/admin",
    icon: <Icon as={FaIdCard} width="20px" height="20px" color="inherit" />,
    path: "/cards",
    component: CardsPage,
  },
  {
    name: "Configurações",
    layout: "/admin",
    icon: <Icon as={FiSettings} width="20px" height="20px" color="inherit" />,
    path: "/settings",
    pathPrevious: "/#/dashboard",
    namePrevious: "Início",
    component: Settings,
  },
  {
    name: "Entrar",
    layout: "/auth",
    hideOnSideBar: true,
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignIn,
  },
  {
    name: "Política de Privacidade",
    layout: "/auth",
    hideOnSideBar: true,
    path: "/privacy",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: Privacy,
  },
];

export default routes;
